<template>
    <div class="bg-gradient-to-r from-primary to-secondary text-white py-8 text-center shadow-lg">
      <div class="flex flex-col items-center">
        <font-awesome-icon v-if="icon" :icon="icon" class="text-4xl mb-2" />
        <h1 :class="titleClass">{{ title }}</h1>
        <p :class="subtitleClass">{{ subtitle }}</p>
      </div>
    </div>
</template>

<script>
export default {
  name: "SubHeader",
  props: {
    icon: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    titleClass: {
      type: String,
      default: "text-3xl font-bold",
    },
    subtitleClass: {
      type: String,
      default: "text-lg",
    },
  },
};
</script>