<template>
  <div class="grid h-full min-h-screen grid-cols-1 lg:grid-cols-3">
    <div class="hidden lg:block col-span-2 bg-cover bg-center bg-login-image"></div>
    <div class="flex items-center justify-center bg-white px-6 py-8">
      <div class="w-full max-w-md">
        <h2 class="text-4xl font-bold text-secondary text-center mb-4">Algebra CTF</h2>
        <div class="border-b-2 border-gray-200 mb-6"></div>

        <form @submit.prevent="handleLogin">
          <div class="mb-4">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Username</label>
            <input
              type="text"
              v-model="username"
              class="border px-3 py-3 placeholder-gray-400 text-gray-700 rounded shadow focus:outline-none focus:ring focus:border-primary w-full"
              placeholder="username"
              required
            />
          </div>
          <div class="mb-6">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Password</label>
            <input
              type="password"
              v-model="password"
              class="border px-3 py-3 placeholder-gray-400 text-gray-700 rounded shadow focus:outline-none focus:ring focus:border-primary w-full"
              placeholder="************"
              required
            />
          </div>
          <button
            type="submit"
            class="w-full bg-primary text-white py-3 rounded shadow-md hover:bg-secondary transition duration-200 ease-in-out font-bold uppercase"
          >
            Sign In
          </button>
        </form>

        <div class="border-t border-gray-300 my-4"></div>

        <div class="text-center mt-4">
          <button
            @click="goToRegister"
            class="w-full bg-secondary text-white py-3 rounded shadow-md hover:bg-primary transition duration-200 ease-in-out font-bold uppercase"
          >
            Register
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  name: 'LoginPage',
  setup() {
    const router = useRouter();
    const toast = useToast(); // Initialize toast
    const username = ref('');
    const password = ref('');

    const handleLogin = async () => {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/login/`, {
          username: username.value,
          password: password.value,
        });

        if (response.status === 200) {
          const { access, refresh } = response.data;
          localStorage.setItem('access_token', access);
          localStorage.setItem('refresh_token', refresh);
          toast.success('Login successful!');
          router.push('/');
        }
      } catch (error) {
        if (error.response?.status === 401) {
          toast.error('Invalid username or password.');
        } else {
          toast.error('An unexpected error occurred. Please try again later.');
        }
      }
    };

    const goToRegister = () => {
      router.push('/register');
    };

    return {
      username,
      password,
      handleLogin,
      goToRegister,
    };
  },
};
</script>

<style scoped>
.bg-login-image {
  background-image: url('@/assets/img/login-background.jpg');
  background-size: cover;
  background-position: center;
}
</style>
