import axios from 'axios';
// import jwtDecode from 'jwt-decode';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || '/api', // Fallback to '/api'
  timeout: 10000,
});

// Request Interceptor: Add Authorization Header
axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

// Response Interceptor: Refresh Token
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent loops

      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken) {
        try {
          const response = await axios.post('/refresh/', { refresh: refreshToken });
          const { access } = response.data;

          // Store new token and retry request
          localStorage.setItem('access_token', access);
          originalRequest.headers.Authorization = `Bearer ${access}`;
          return axiosInstance(originalRequest);
        } catch {
          localStorage.clear(); // Clear tokens if refresh fails
          window.location.href = '/login'; // Redirect to login
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
