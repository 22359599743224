<template>
    <div class="flex items-center justify-center min-h-screen bg-gray-100 p-8">
      <div class="bg-white max-w-lg w-full p-10 rounded-lg shadow-lg text-center">
        <!-- Icon for success or failure -->
        <div v-if="verificationStatus === 'success'" class="icon-container bg-green-500 text-white mx-auto mb-6">
          <svg class="h-16 w-16" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <div v-else-if="verificationStatus === 'failure'" class="icon-container bg-red-500 text-white mx-auto mb-6">
          <svg class="h-16 w-16" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
  
        <!-- Title and message -->
        <h1 v-if="verificationStatus === 'success'" class="text-3xl font-bold text-green-600 mb-2">Email Verified!</h1>
        <p v-if="verificationStatus === 'success'" class="text-lg text-gray-700">
          Thank you for verifying your email address. Your account is now active.
        </p>
  
        <h1 v-else class="text-3xl font-bold text-red-600 mb-2">Verification Failed</h1>
        <p v-if="errorMessage" class="text-lg text-gray-700">{{ errorMessage }}</p>
  
        <!-- Resend Verification Section -->
        <div v-if="verificationStatus === 'failure'" class="mt-6">
          <input
            v-if="!email"
            v-model="emailInput"
            type="email"
            placeholder="Enter your email address"
            class="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4 focus:outline-none focus:ring focus:ring-primary"
          />
          <button
            @click="resendVerification"
            class="bg-primary text-white py-2 px-4 rounded-full hover:bg-secondary transition duration-200"
          >
            Resend Verification Email
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import { useToast } from "vue-toastification";
  
  export default {
    name: "VerifyEmailPage",
    setup() {
      const verificationStatus = ref("");
      const errorMessage = ref("");
      const email = ref("");
      const emailInput = ref("");
      const toast = useToast();
  
      const resendVerification = async () => {
        const emailToSend = email.value || emailInput.value;
        if (!emailToSend) {
          toast.error("Please provide a valid email address to resend verification.");
          return;
        }
  
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/resend-verification/`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: emailToSend }),
          });
  
          if (response.ok) {
            toast.success("Verification email resent successfully!");
          } else {
            const errorData = await response.json();
            toast.error(errorData.message || "Failed to resend verification email.");
          }
        } catch (error) {
          console.error("Error resending verification:", error);
          toast.error("An error occurred while resending the verification email.");
        }
      };
  
      onMounted(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        email.value = urlParams.get("email"); // Capture email from URL if available
  
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/verify-email/${token}/`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });
  
          if (response.ok) {
            verificationStatus.value = "success";
            toast.success("Email verified successfully!");
          } else {
            const errorData = await response.json();
            verificationStatus.value = "failure";
            errorMessage.value = errorData.message;
            toast.error(errorData.message || "Email verification failed.");
          }
        } catch (error) {
          console.error("Verification error:", error);
          verificationStatus.value = "failure";
          errorMessage.value = "An error occurred during verification. Please try again.";
          toast.error("An error occurred during email verification.");
        }
      });
  
      return { verificationStatus, errorMessage, resendVerification, email, emailInput };
    },
  };
  </script>
  
  <style scoped>
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  .bg-primary {
    background-color: #de700f;
  }
  
  .bg-secondary {
    background-color: #bc1865;
  }
  </style>
  