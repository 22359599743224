<template>
  <div class="container mx-auto p-6">
    <!-- Search Bar -->
    <div v-if="!topResultsOnly" class="flex items-center justify-between mb-6">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search by username"
        class="border rounded-lg px-4 py-2 w-full sm:w-1/3 focus:outline-none focus:ring focus:ring-primary"
      />
      <div class="flex space-x-4">
        <button
          @click="filterScoreboard('all')"
          :class="{
            'bg-primary text-white': filter === 'all',
          }"
          class="pill-button-light hover:bg-primary hover:text-white"
        >
          All
        </button>
        <button
          @click="filterScoreboard('jeopardy')"
          :class="{
            'bg-primary text-white': filter === 'jeopardy',
          }"
          class="pill-button-light hover:bg-primary hover:text-white"
        >
          Jeopardy
        </button>
        <button
          @click="filterScoreboard('boot2root')"
          :class="{
            'bg-primary text-white': filter === 'boot2root',
          }"
          class="pill-button-light hover:bg-primary hover:text-white"
        >
          Boot2Root
        </button>
      </div>
    </div>

    <!-- Scoreboard Table -->
    <div class="scoreboard-box bg-gray-50 p-6 rounded-xl shadow-lg">
      <table class="w-full text-left bg-white shadow-md rounded-md">
        <thead>
          <tr class="bg-gray-100 border-b border-gray-200">
            <th class="py-3 px-4 text-gray-700">Rank</th>
            <th class="py-3 px-4 text-gray-700">User</th>
            <th class="py-3 px-4 text-gray-700">Points</th>
            <th class="py-3 px-4 text-gray-700">Jeopardy</th>
            <th class="py-3 px-4 text-gray-700">Boot2Root</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="entry in limitedScoreboard"
            :key="entry.rank"
            class="hover:bg-gray-50 transition"
            :class="{
              'bg-primary text-white': entry.username === highlightUser,
            }"
          >
            <td class="py-1 px-4 border-t">{{ entry.rank }}</td>
            <td class="py-1 px-4 border-t">{{ entry.username }}</td>
            <td class="py-1 px-4 border-t">{{ entry.total_points }}</td>
            <td class="py-1 px-4 border-t">{{ entry.jeopardy_completed }}</td>
            <td class="py-1 px-4 border-t">{{ entry.boot2root_completed }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div v-if="!topResultsOnly" class="flex justify-between items-center mt-6">
      <button
        :disabled="page === 1"
        @click="fetchScoreboard(page - 1)"
        class="pill-button-light hover:bg-primary hover:text-white"
      >
        Previous
      </button>
      <span>Page {{ page }} of {{ totalPages }}</span>
      <button
        :disabled="page === totalPages"
        @click="fetchScoreboard(page + 1)"
        class="pill-button-light hover:bg-primary hover:text-white"
      >
        Next
      </button>
    </div>

    <!-- Error Message -->
    <p v-if="errorMessage" class="text-red-500 mt-4 text-center">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import axiosInstance from "../services/axiosinstance";

export default {
  name: "CTFScoreboard",
  props: {
    maxResults: {
      type: Number,
      default: 0, // 0 means no limit
    },
    highlightUser: {
      type: String,
      default: null,
    },
    topResultsOnly: {
      type: Boolean,
      default: false, // If true, disables pagination and search
    },
  },
  setup(props) {
    const scoreboard = ref([]);
    const errorMessage = ref("");
    const filter = ref("all");
    const searchQuery = ref("");
    const page = ref(1);
    const totalPages = ref(1);

    const fetchScoreboard = async (pageNumber) => {
      try {
        if (props.topResultsOnly && props.maxResults > 0) {
          // Fetch top results
          const response = await axiosInstance.get("/scoreboard/", {
            params: { page_size: props.maxResults },
          });
          scoreboard.value = response.data.results;
        } else {
          // Fetch paginated data
          page.value = pageNumber;
          const response = await axiosInstance.get("/scoreboard/", {
            params: { page: pageNumber },
          });
          scoreboard.value = response.data.results;
          totalPages.value = Math.ceil(response.data.count / 10);
        }
      } catch (error) {
        console.error("Error fetching scoreboard:", error);
        errorMessage.value =
          "Failed to load scoreboard. Please try again later.";
      }
    };

    const filteredScoreboard = computed(() => {
      let results = [...scoreboard.value];
      if (filter.value === "jeopardy") {
        results = results.filter((entry) => entry.jeopardy_completed > 0);
      } else if (filter.value === "boot2root") {
        results = results.filter((entry) => entry.boot2root_completed > 0);
      }
      if (searchQuery.value) {
        results = results.filter((entry) =>
          entry.username
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        );
      }
      return results;
    });

    const limitedScoreboard = computed(() => {
      if (props.topResultsOnly && props.maxResults > 0) {
        return filteredScoreboard.value.slice(0, props.maxResults);
      }
      return filteredScoreboard.value;
    });

    const filterScoreboard = (type) => {
      filter.value = type;
    };

    onMounted(() => fetchScoreboard(page.value));

    return {
      scoreboard,
      errorMessage,
      filter,
      filterScoreboard,
      filteredScoreboard,
      limitedScoreboard,
      searchQuery,
      fetchScoreboard,
      page,
      totalPages,
    };
  },
};
</script>
