<template>
    <div class="min-h-screen bg-gray-50">
        <SubHeader
          :icon="['fas', 'chart-bar']"
          title="Leaderboard"
          subtitle="See how you rank against other players!"
        />
  
      <!-- Main Content -->
      <div class="container mx-auto p-6">
  
        <!-- Settings Section -->
        <div class="flex justify-between items-center mb-4">
          <!-- Page Size Selector -->
          <div class="flex items-center space-x-2">
            <label for="pageSize" class="text-gray-700 font-medium">Results per page:</label>
            <select
              id="pageSize"
              v-model="pageSize"
              @change="fetchScoreboard(currentPage)"
              class="border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-primary"
            >
              <option v-for="size in pageSizeOptions" :key="size" :value="size">
                {{ size }}
              </option>
            </select>
          </div>
  
          <!-- Pagination Info -->
          <div class="text-gray-600">
            Page {{ currentPage }} of {{ totalPages }}
          </div>
        </div>
  
        <!-- Scrollable Leaderboard -->
        <div class="overflow-y-auto max-h-[500px] border rounded-lg shadow-md bg-white">
          <CTFScoreboard :highlightUser="currentUser" :data="filteredScoreboard" />
        </div>

      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted } from "vue";
  import axiosInstance from "../services/axiosinstance";
  import CTFScoreboard from "../components/CTFScoreboard.vue";
import SubHeader from "../components/SubHeader.vue";
  
  export default {
    name: "CTFLeaderboard",
    components: { CTFScoreboard, SubHeader },
    setup() {
      const scoreboard = ref([]);
      const searchQuery = ref("");
      const currentPage = ref(1);
      const totalPages = ref(1);
      const currentUser = ref(null);
      const pageSize = ref(10);
      const pageSizeOptions = [10, 20, 50];
  
      const fetchScoreboard = async (pageNumber) => {
        try {
          const response = await axiosInstance.get("/scoreboard/", {
            params: { page: pageNumber, page_size: pageSize.value },
          });
  
          scoreboard.value = response.data.results;
          totalPages.value = Math.ceil(response.data.count / pageSize.value);
          currentPage.value = pageNumber;
  
          // Fetch current user data
          const userResponse = await axiosInstance.get("/profile/");
          currentUser.value = userResponse.data.username;
        } catch (error) {
          console.error("Error fetching scoreboard:", error);
        }
      };
  
      const filteredScoreboard = computed(() => {
        if (!searchQuery.value.trim()) {
          return scoreboard.value;
        }
        return scoreboard.value.filter((entry) =>
          entry.username.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      });
  
      const nextPage = () => {
        if (currentPage.value < totalPages.value) {
          fetchScoreboard(currentPage.value + 1);
        }
      };
  
      const prevPage = () => {
        if (currentPage.value > 1) {
          fetchScoreboard(currentPage.value - 1);
        }
      };
  
      onMounted(() => fetchScoreboard(currentPage.value));
  
      return {
        scoreboard,
        searchQuery,
        currentPage,
        totalPages,
        currentUser,
        pageSize,
        pageSizeOptions,
        filteredScoreboard,
        fetchScoreboard,
        nextPage,
        prevPage,
      };
    },
  };
  </script>
  
