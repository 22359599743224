<template>
  <!-- Main Scrollable Container -->
  <div class="min-h-screen bg-gray-50 overflow-y-auto p-6">
    <div class="max-w-screen-lg mx-auto space-y-8">
      <!-- Subheader Component -->
      <SubHeader
        :icon="['fas', 'user']"
        title="User Profile"
        subtitle="Details about your CTF journey."
      />

      <!-- User Information Card -->
      <div class="bg-white shadow-md rounded-lg p-6">
        <div class="flex items-center mb-4">
          <font-awesome-icon icon="user" class="text-primary h-8 w-8 mr-2" />
          <h2 class="text-2xl font-bold text-gray-800">User Information</h2>
        </div>
        <div class="grid grid-cols-2 gap-4 text-gray-600">
          <p><strong>Username:</strong> {{ userData.username }}</p>
          <p><strong>Email:</strong> {{ userData.email }}</p>
          <p><strong>Total Points:</strong> {{ userData.totalPoints }}</p>
          <p><strong>Challenges Solved:</strong> {{ userData.solved_jeopardy }}</p>
          <p><strong>Machines Solved:</strong> {{ userData.solved_boot2root }}</p>
        </div>
      </div>

      <!-- Charts Section -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Solve Percentages Chart -->
        <div class="bg-white shadow-md rounded-lg p-4">
          <div class="flex items-center mb-2">
            <font-awesome-icon icon="chart-pie" class="text-primary h-6 w-6 mr-2" />
            <h3 class="text-lg font-bold text-gray-800">Solve Percentages</h3>
          </div>
          <div v-if="userData.solved > 0" class="h-64">
            <apexchart
              type="pie"
              :options="solveChartOptions"
              :series="[userData.solved, userData.failed]"
              width="100%"
              height="100%"
            />
          </div>
          <p v-else class="text-gray-500 text-center mt-6">No challenges solved yet.</p>
        </div>

        <!-- Category Breakdown Chart -->
        <div class="bg-white shadow-md rounded-lg p-4">
          <div class="flex items-center mb-2">
            <font-awesome-icon icon="chart-bar" class="text-primary h-6 w-6 mr-2" />
            <h3 class="text-lg font-bold text-gray-800">Category Breakdown</h3>
          </div>
          <div v-if="categorySeries.length > 0" class="h-64">
            <apexchart
              type="pie"
              :options="categoryChartOptions"
              :series="categorySeries"
              width="100%"
              height="100%"
            />
          </div>
          <p v-else class="text-gray-500 text-center mt-6">No data available for categories.</p>
        </div>

        <!-- Score Over Time Chart -->
        <div class="bg-white shadow-md rounded-lg p-4 md:col-span-2">
          <div class="flex items-center mb-2">
            <font-awesome-icon icon="clock" class="text-primary h-6 w-6 mr-2" />
            <h3 class="text-lg font-bold text-gray-800">Score over Time</h3>
          </div>
          <div v-if="scoreTimelineSeries.length > 0" class="h-80">
            <apexchart
              type="line"
              :options="timelineChartOptions"
              :series="[{ name: userData.username, data: scoreTimelineSeries }]"
              width="100%"
              height="100%"
            />
          </div>
          <p v-else class="text-gray-500 text-center mt-6">No score data available yet.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axiosInstance from '../services/axiosinstance';
import VueApexCharts from 'vue3-apexcharts';
import SubHeader from '../components/SubHeader.vue';

export default {
  components: { apexchart: VueApexCharts, SubHeader },
  setup() {
    const userData = ref({
      username: '',
      email: '',
      totalPoints: 0,
      solved_jeopardy: 0,
      solved_boot2root: 0,
      solved: 0,
      failed: 0,
    });

    const completedChallenges = ref([]);
    const categoryCounts = ref({});
    const scoreTimelineData = ref([]);

    const fetchData = async () => {
      try {
        const userResponse = await axiosInstance.get('/profile/');
        userData.value = userResponse.data;

        const completedChallengesResponse = await axiosInstance.get('/user/completed_challenges/');
        completedChallenges.value = completedChallengesResponse.data;

        const allChallengesResponse = await axiosInstance.get('/challenges/');
        const totalChallenges = allChallengesResponse.data.length;

        processChallengeData(totalChallenges);
      } catch (error) {
        console.error('Error fetching profile or challenges data:', error);
      }
    };

    const processChallengeData = (totalChallenges) => {
      userData.value.solved_jeopardy = 0;
      userData.value.solved_boot2root = 0;
      userData.value.totalPoints = 0;

      categoryCounts.value = {};
      scoreTimelineData.value = [];

      completedChallenges.value.forEach((challenge) => {
        if (challenge.challenge_type === 'jeopardy') {
          userData.value.solved_jeopardy += 1;
        } else if (challenge.challenge_type === 'boot2root') {
          userData.value.solved_boot2root += 1;
        }

        userData.value.totalPoints += challenge.points_earned;
        categoryCounts.value[challenge.category] = (categoryCounts.value[challenge.category] || 0) + 1;

        scoreTimelineData.value.push({
          x: challenge.completed_at,
          y: userData.value.totalPoints,
        });
      });

      userData.value.solved = completedChallenges.value.length;
      userData.value.failed = totalChallenges - userData.value.solved;
    };

    onMounted(fetchData);

    const solveChartOptions = {
      labels: ['Solved', 'Unsolved'],
      colors: ['#28a745', '#dc3545'],
      legend: { position: 'bottom' },
    };

    const categoryChartOptions = computed(() => ({
      labels: Object.keys(categoryCounts.value),
      colors: ['#ff6384', '#36a2eb', '#cc65fe', '#ffce56'],
      legend: { position: 'bottom' },
    }));

    const categorySeries = computed(() => Object.values(categoryCounts.value));
    const scoreTimelineSeries = computed(() => scoreTimelineData.value);

    const timelineChartOptions = {
      xaxis: { type: 'datetime', title: { text: 'Date' } },
      yaxis: { title: { text: 'Score' } },
      colors: ['#ff6384'],
    };

    return {
      userData,
      solveChartOptions,
      categoryChartOptions,
      categorySeries,
      timelineChartOptions,
      scoreTimelineSeries,
    };
  },
};
</script>
