<template>
    <div class="flex h-full min-h-screen items-center justify-center bg-gray-100 px-4">
        <div class="w-full max-w-md bg-white p-8 rounded-lg shadow-lg relative">
            <h2 class="text-3xl font-bold text-center text-secondary mb-6">Register</h2>

            <form @submit.prevent="handleRegister">
                <!-- Username field -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Username</label>
                    <input
                        type="text"
                        v-model="username"
                        class="border px-3 py-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-secondary"
                        placeholder="Enter your username"
                        required
                    />
                </div>

                <!-- Email field -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Email</label>
                    <input
                        type="email"
                        v-model="email"
                        class="border px-3 py-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-secondary"
                        placeholder="Enter your email"
                        required
                    />
                </div>

                <!-- Password field -->
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Password</label>
                    <input
                        type="password"
                        v-model="password"
                        class="border px-3 py-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-secondary"
                        placeholder="Enter your password"
                        required
                    />
                </div>

                <!-- Confirm Password field -->
                <div class="mb-6">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Confirm Password</label>
                    <input
                        type="password"
                        v-model="confirmPassword"
                        class="border px-3 py-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-secondary"
                        placeholder="Confirm your password"
                        required
                    />
                </div>

                <!-- Register button -->
                <button
                    type="submit"
                    class="bg-primary text-white font-bold py-2 px-4 rounded w-full hover:bg-secondary transition duration-150 ease-in-out"
                >
                    Register
                </button>
            </form>

            <!-- Resend Verification Token -->
            <div class="mt-4">
                <button
                    @click="resendVerificationToken"
                    class="text-sm text-blue-500 hover:underline focus:outline-none"
                >
                    Resend Verification Token
                </button>
            </div>

            <!-- Loading Overlay -->
            <loading-overlay v-model:active="loading" :is-full-page="true" />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import axiosInstance from '../services/axiosinstance';

export default {
    name: 'RegisterPage',
    setup() {
        const username = ref('');
        const email = ref('');
        const password = ref('');
        const confirmPassword = ref('');
        const toast = useToast();

        const handleRegister = async () => {
            if (password.value !== confirmPassword.value) {
                toast.error('Passwords must match.');
                return;
            }

            const payload = {
                username: username.value,
                email: email.value,
                password: password.value,
                confirm_password: confirmPassword.value,
            };

            // Show persistent loading toast
            const loadingToastId = toast.info('Processing your registration...', {
                icon: '🔄',
                timeout: false, // Keep the toast open until dismissed
                closeOnClick: false,
            });

            try {
                const response = await axiosInstance.post('/register/', payload);
                if (response.status === 201) {
                    toast.dismiss(loadingToastId); // Dismiss the loading toast
                    toast.success(response.data.message || 'Registration successful!');
                    username.value = '';
                    email.value = '';
                    password.value = '';
                    confirmPassword.value = '';
                }
            } catch (error) {
                toast.dismiss(loadingToastId); // Dismiss the loading toast
                if (error.response) {
                    const { data } = error.response;
                    Object.keys(data).forEach((field) => {
                        toast.error(`${field}: ${data[field][0]}`);
                    });
                } else {
                    toast.error('An error occurred. Please try again.');
                }
            }
        };

        const resendVerificationToken = async () => {
            if (!email.value) {
                toast.error('Please enter your email to resend the verification token.');
                return;
            }

            // Show persistent loading toast
            const loadingToastId = toast.info('Resending verification token...', {
                icon: '📧',
                timeout: false,
                closeOnClick: false,
            });

            try {
                const response = await axiosInstance.post('/resend-verification/', { email: email.value });
                toast.dismiss(loadingToastId); // Dismiss the loading toast
                if (response.status === 200) {
                    toast.success('Verification token resent successfully!');
                }
            } catch (error) {
                toast.dismiss(loadingToastId); // Dismiss the loading toast
                if (error.response) {
                    toast.error(error.response.data.message || 'Failed to resend verification token.');
                } else {
                    toast.error('An error occurred while resending the verification token.');
                }
            }
        };

        return {
            username,
            email,
            password,
            confirmPassword,
            handleRegister,
            resendVerificationToken,
        };
    },
};
</script>
