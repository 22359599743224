<template>
    <footer class="bg-gray-800 text-white py-6 mt-10">
      <div class="container mx-auto text-center">
        <p class="text-sm">&copy; {{ new Date().getFullYear() }} Algebra CTF. All rights reserved.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'AppFooter',
  };
  </script>