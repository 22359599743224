<template>
  <div
    class="relative bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg hover:scale-105 transition-transform duration-200 cursor-pointer border border-gray-200"
    @click="$emit('click', challenge)"
  >
    <!-- Header Section with Background Gradient -->
    <div class="relative h-24 bg-gradient-to-r from-primary to-secondary">
      <div class="absolute inset-0 flex items-center justify-center">
        <h2 class="text-lg font-bold text-white px-4 truncate">{{ challenge.name }}</h2>
      </div>
    </div>

    <!-- Content Section -->
    <div class="p-4">
      <!-- Badges Section -->
      <div class="mb-4 flex flex-wrap gap-2">
        <!-- Challenge Type Badge -->
        <span
          class="inline-block px-3 py-1 text-xs font-semibold rounded-lg shadow-sm"
          :class="{
            'bg-blue-100 text-blue-800': challenge.challenge_type === 'jeopardy',
            'bg-green-100 text-green-800': challenge.challenge_type === 'boot2root',
          }"
        >
          {{ challenge.challenge_type }}
        </span>

        <!-- Challenge Category Badge -->
        <span
          v-if="challenge.category"
          class="inline-block px-3 py-1 text-xs font-semibold rounded-lg shadow-sm"
          :class="{
            'bg-purple-100 text-purple-800': challenge.category === 'webapp',
            'bg-yellow-100 text-yellow-800': challenge.category === 'crypto',
            'bg-red-100 text-red-800': challenge.category === 'pwn',
            'bg-teal-100 text-teal-800': challenge.category === 'forensics',
            'bg-pink-100 text-pink-800': challenge.category === 'steganography',
            'bg-indigo-100 text-indigo-800': challenge.category === 'encode',
            'bg-gray-100 text-gray-800': challenge.category === 'other',
          }"
        >
          {{ challenge.category }}
        </span>

        <!-- OS Badge -->
        <span
          v-if="challenge.challenge_type === 'boot2root' && challenge.os"
          class="inline-block px-3 py-1 text-xs font-semibold rounded-lg shadow-sm"
          :class="{
            'bg-orange-100 text-orange-800': challenge.os === 'linux',
            'bg-cyan-100 text-cyan-800': challenge.os === 'windows',
          }"
        >
          {{ challenge.os }}
        </span>

        <!-- Difficulty Badge -->
        <span
          v-if="challenge.difficulty"
          class="inline-block px-3 py-1 text-xs font-semibold rounded-lg shadow-sm"
          :class="{
            'bg-green-200 text-green-900': challenge.difficulty === 'easy',
            'bg-yellow-200 text-yellow-900': challenge.difficulty === 'medium',
            'bg-red-200 text-red-900': challenge.difficulty === 'hard',
          }"
        >
          {{ challenge.difficulty }}
        </span>
      </div>

      <!-- Points -->
      <div class="flex justify-between items-center mb-3">
        <span class="text-sm font-bold text-primary">
          {{ challenge.points }} pts
        </span>
      </div>

      <!-- Description -->
      <p class="text-sm text-gray-600 leading-relaxed">
        {{ challenge.description || 'No description available.' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChallengeCard',
  props: {
    challenge: {
      type: Object,
      required: true,
    },
  },
};
</script>