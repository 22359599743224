<template>
  <div class="container mx-auto p-6">
    <h1 class="text-3xl font-bold mb-6">Challenges</h1>

    <!-- Tabs for Filtering -->
    <div class="flex mb-6 space-x-4 border-b-2 pb-2">
      <button
        v-for="category in challengeCategories"
        :key="category.value"
        @click="fetchChallenges(category.value)"
        class="text-gray-600 hover:text-primary focus:outline-none pb-1 border-b-2"
        :class="{
          'border-primary text-primary font-semibold': selectedCategory === category.value,
        }"
      >
        {{ category.label }}
      </button>
    </div>

    <!-- Challenge Cards -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <ChallengeCard
        v-for="challenge in challenges"
        :key="challenge.id"
        :challenge="challenge"
        @click="openModal(challenge.id)"
      />
    </div>

    <!-- Challenge Modal -->
    <ChallengeModal
      v-if="selectedChallengeId"
      :visible="!!selectedChallengeId"
      :challengeId="selectedChallengeId"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axiosInstance from '../services/axiosinstance';
import ChallengeCard from '../components/ChallengesCard.vue';
import ChallengeModal from '../components/ChallengeModal.vue';

export default {
  name: 'ChallengesPage',
  components: { ChallengeCard, ChallengeModal },
  setup() {
    const challenges = ref([]);
    const selectedChallengeId = ref(null);
    const selectedCategory = ref('');
    const challengeCategories = ref([
      { value: '', label: 'All Categories' },
      { value: 'webapp', label: 'Web Application' },
      { value: 'crypto', label: 'Cryptography' },
      { value: 'pwn', label: 'Binary Exploitation' },
      { value: 'forensics', label: 'Forensics' },
      { value: 'steganography', label: 'Steganography' },
      { value: 'encode', label: 'Encoding' },
      { value: 'other', label: 'Other' },
    ]);

    // Fetch challenges based on category
    const fetchChallenges = async (category) => {
      selectedCategory.value = category;
      try {
        const response = await axiosInstance.get('/challenges/', {
          params: { type: 'jeopardy', category: category },
        });
        challenges.value = response.data;
      } catch (error) {
        console.error('Error fetching challenges:', error);
      }
    };

    // Open modal for a specific challenge
    const openModal = (id) => {
      selectedChallengeId.value = id;
    };

    // Close modal
    const closeModal = () => {
      selectedChallengeId.value = null;
    };

    // Fetch challenges on component mount
    onMounted(() => fetchChallenges(selectedCategory.value));

    return {
      challenges,
      selectedChallengeId,
      challengeCategories,
      selectedCategory,
      fetchChallenges,
      openModal,
      closeModal,
    };
  },
};
</script>
