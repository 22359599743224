<template>
  <div v-if="visible" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white p-6 rounded shadow-md text-center">
      <h2 class="text-xl font-semibold mb-4">Session Expiring Soon</h2>
      <p class="mb-4">Your session is about to expire. Do you want to continue?</p>
      <button @click="continueSession" class="bg-primary text-white px-4 py-2 rounded mr-2">Yes, Continue</button>
      <button @click="endSession" class="bg-red-500 text-white px-4 py-2 rounded">No, Logout</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SessionContinuationModal',
  props: ['visible'],
  setup(props, { emit }) {
    const continueSession = () => {
      emit('continue-session');
    };

    const endSession = () => {
      emit('close-modal');
    };

    return { continueSession, endSession };
  },
};
</script>

<style scoped>
.bg-primary {
  background-color: #4a90e2;
}
</style>
