<template>
  <div class="recent-challenges-box p-3 bg-gray-50 rounded-lg shadow-md">
    <ul v-if="challenges.length" class="space-y-2">
      <li v-for="challenge in challenges" :key="challenge.completed_at" class="border-b pb-3">
        <p><strong>{{ challenge.challenge_name }}</strong> ({{ challenge.challenge_type }})</p>
        <p>Points Earned: {{ challenge.points_earned }}</p>
        <p class="text-sm text-gray-500">Completed at: {{ new Date(challenge.completed_at).toLocaleString() }}</p>
      </li>
    </ul>
    <p v-else class="text-gray-500">No recent challenges completed.</p>
  </div>
</template>
  
<script>
import { ref, onMounted } from 'vue';
  
export default {
  name: 'RecentChallenges',
  setup() {
    const challenges = ref([]);
    const errorMessage = ref('');

    const fetchRecentChallenges = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${process.env.VUE_APP_API_URL}/user/recent-challenges/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          challenges.value = await response.json();
        } else {
          errorMessage.value = 'Failed to load recent challenges.';
        }
      } catch (error) {
        console.error('Error fetching recent challenges:', error);
        errorMessage.value = 'An error occurred. Please try again later.';
      }
    };

    onMounted(fetchRecentChallenges);

    return { challenges, errorMessage };
  },
};
</script>
  
<style scoped>
.recent-challenges-box {
  max-height: 400px;
  overflow-y: auto;
}
</style>
