<template>
  <header class="bg-gray-700 text-white p-4 shadow-md flex justify-between items-center">
    <!-- Site Title -->
    <div
      class="text-2xl font-bold cursor-pointer hover:text-gray-300"
      @click="redirectToLogin"
    >
      Algebra CTF
    </div>

    <!-- Navigation Links and Logout Button -->
    <nav class="flex items-center space-x-4">
      <!-- Use 'exact-active-class' to apply specific active styling to the router links -->
      <router-link
        to="/challenges"
        class="pill-link-light"
        active-class="active-pill-link"
      >
        Challenges
      </router-link>
      <router-link
        to="/machines"
        class="pill-link-light"
        active-class="active-pill-link"
      >
        Machines
      </router-link>
      <router-link
        to="/scoreboard"
        class="pill-link-light"
        active-class="active-pill-link"
      >
        Scoreboard
      </router-link>
      <router-link
        to="/profile"
        class="pill-link-light"
        active-class="active-pill-link"
      >
        Profile
      </router-link>
      <button @click="logout" class="pill-link-light">
        Logout
      </button>
    </nav>
  </header>
</template>

<script>
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export default {
  name: 'AppHeader',
  setup() {
    const router = useRouter();
    const toast = useToast(); // Initialize toast notifications

    const redirectToLogin = () => {
      router.push('/login');
    };

    const logout = () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      toast.success('You have been logged out successfully.');
      router.push({ name: 'Login' });
    };

    return {
      redirectToLogin,
      logout,
    };
  },
};
</script>
