import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import './assets/styles.css';

// Import FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import specific icons
import { faHome, faChartBar, faUser, faChartPie, faClock } from '@fortawesome/free-solid-svg-icons';

// Initialize app
const app = createApp(App);

// Add icons to library
library.add(faHome, faChartBar, faUser, faChartPie, faClock);

// Register FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Use plugins
app.use(router);
app.use(Toast, {
    position: 'top-right',
    timeout: 1200,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    hideProgressBar: false,
});

// Mount the app
app.mount('#app');
