<template>
  <!-- Modal -->
  <div
    v-if="visible"
    class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 w-full max-w-xl relative">
      <!-- Close Button -->
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        @click="closeModal"
      >
        ✖
      </button>

      <!-- Loading State -->
      <div v-if="loading" class="text-center text-gray-500 py-6">
        <div class="loader mb-4"></div>
        Loading challenge details...
      </div>

      <!-- Challenge Details -->
      <div v-else-if="challengeDetails">
        <!-- Title -->
        <h2 class="text-3xl font-bold text-primary mb-6 text-center">
          {{ challengeDetails.name }}
        </h2>

        <!-- Description -->
        <p class="mb-4 text-gray-700 text-center leading-relaxed">
          {{ challengeDetails.description }}
        </p>

        <!-- Challenge Info -->
        <div class="grid grid-cols-2 gap-4 mb-6 text-center">
          <div class="p-4 border rounded-lg shadow-sm bg-gray-50">
            <p class="text-lg font-semibold">🏆 Points</p>
            <p class="text-xl font-bold">{{ challengeDetails.points }}</p>
          </div>
          <div class="p-4 border rounded-lg shadow-sm bg-gray-50">
            <p class="text-lg font-semibold">📖 Type</p>
            <p class="text-xl font-bold capitalize">{{ challengeDetails.challenge_type }}</p>
          </div>
        </div>

        <!-- Download File -->
        <div v-if="challengeDetails.file" class="mb-6 text-center">
          <a
            :href="challengeDetails.file"
            download
            class="inline-flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-secondary transition duration-200"
          >
            📂 {{ getFileName(challengeDetails.file) }}
          </a>
        </div>
        <div v-else class="text-gray-500 mb-6 text-center">
          No file attached for this challenge.
        </div>

        <!-- Flag Submission Form -->
        <form @submit.prevent="submitFlag" class="mt-4">
          <label class="block text-sm font-medium text-gray-700 mb-2">Submit Flag</label>
          <input
            type="text"
            v-model="flagText"
            class="border border-gray-300 rounded px-4 py-2 w-full mb-4 focus:outline-none focus:ring focus:ring-primary"
            placeholder="Enter the flag"
            required
          />
          <button
            type="submit"
            :disabled="submitting"
            class="bg-primary text-white py-2 rounded w-full hover:bg-secondary transition duration-200 disabled:opacity-50"
          >
            {{ submitting ? 'Submitting...' : 'Submit Flag' }}
          </button>
        </form>
      </div>

      <!-- Error State -->
      <div v-else class="text-center text-red-500 py-6">
        Failed to load challenge details.
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import axiosInstance from '../services/axiosinstance';
import { useToast } from 'vue-toastification';

export default {
  name: 'ChallengeModal',
  props: {
    challengeId: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    // Reactive Variables
    const challengeDetails = ref(null);
    const loading = ref(false);
    const flagText = ref('');
    const submitting = ref(false); // For Submit Button Loading State
    const toast = useToast();

    // Close Modal
    const closeModal = () => {
      emit('close');
    };

    // Fetch Challenge Details
    const fetchChallengeDetails = async () => {
      loading.value = true;
      challengeDetails.value = null;
      try {
        const response = await axiosInstance.get(`/challenges/?id=${props.challengeId}`);
        challengeDetails.value = response.data;
      } catch (error) {
        console.error('Error fetching challenge details:', error);
        toast.error('Failed to load challenge details.');
        emit('close'); // Close modal on error
      } finally {
        loading.value = false;
      }
    };

    const getFileName = (url) => {
      if (!url) return ''; // In case the URL is empty or undefined
      const parts = url.split('/');
      return parts[parts.length - 1]; // Return only the last part of the URL
    };

    // Submit Flag
    const submitFlag = async () => {
      submitting.value = true;
      try {
        const response = await axiosInstance.post('/submit-flag/', {
          flag_text: flagText.value,
          challenge: props.challengeId,
        });
        toast.success(response.data.message || 'Flag submitted successfully!');
        closeModal();
      } catch (error) {
        toast.error(error.response?.data?.message || 'Failed to submit flag.');
      } finally {
        submitting.value = false;
      }
    };

    // Watch for Visibility Change
    watch(
      () => props.visible,
      (newVal) => {
        if (newVal) {
          fetchChallengeDetails();
        } else {
          challengeDetails.value = null; // Reset on close
        }
      },
      { immediate: true }
    );

    return {
      challengeDetails,
      loading,
      flagText,
      getFileName,
      submitting,
      closeModal,
      submitFlag,
    };
  },
};
</script>
