import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/Home.vue';
import LoginPage from '../views/Login.vue';
import RegisterPage from '../views/Register.vue';
import VerifyEmailPage from '../views/VerifyEmail.vue';
import ChallengesPage from '../views/CTFChallenges.vue';
import MachinesPage from '../views/CTFMachines.vue';
import CTFUserProfile from '../views/CTFUserProfile.vue';
import CTFLeaderboard from '../views/CTFLeaderboard.vue';

function isAuthenticated() {
    return !!localStorage.getItem('access_token');
}

const routes = [
    { path: '/', name: 'Home', component: HomePage, meta: { requiresAuth: true } },
    { path: '/login', name: 'Login', component: LoginPage, meta: { requiresUnauth: true } },
    { path: '/register', name: 'Register', component: RegisterPage, meta: { requiresUnauth: true } },
    { path: '/verify-email', name: 'VerifyEmail', component: VerifyEmailPage, meta: { requiresUnauth: true } },
    { path: '/challenges', name: 'Challenges', component: ChallengesPage, meta: { requiresAuth: true } },
    { path: '/machines', name: 'Machines', component: MachinesPage, meta: { requiresAuth: true } },
    { path: '/scoreboard', name: 'Scoreboard', component: CTFLeaderboard, meta: { requiresAuth: true } },
    { path: '/profile', name: 'Profile', component: CTFUserProfile, meta: { requiresAuth: true } },
];

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL || '/'),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});


router.beforeEach((to, from, next) => {
    const isAuth = isAuthenticated();

    if (to.meta.requiresAuth && !isAuth) {
        next({ name: 'Login' });
    } else if (to.meta.requiresUnauth && isAuth) {
        next({ name: 'Home' });
    } else {
        next();
    }
});

export default router;
